<template>
  <div class="privacy">
    <div class="title">
      <h2>隐私协议</h2>
    </div>
    <h3>导言</h3>
    <div>
      青点互动指海南乐游科技有限公司及其关联方（以下简称“我们”）合法拥有并运营的、标注名称为青点互动的客户端应用程序。我们深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息。我们希望通过本《青点互动隐私政策》（以下也称“本政策”）向您清晰地介绍您在使用我们的产品与/或服务时，我们如何处理您的个人信息，以及我们为您提供的管理、保护这些信息的方式。<span
        class="bold"
        >涉及本政策中与您的权益（可能）存在重大关系的条款以及涉及敏感个人信息的处理，我们已经以字体加粗、下划线或其他合理方式提示您重点阅读。</span
      >
    </div>
    <div class="bold">
      您同意本政策表示您已经了解并同意在相关场景下，为实现功能运行，我们将对您的相关个人信息进行处理。但这并不代表只要您开始使用我们的产品与/或服务，我们将处理本政策中涉及的全部个人信息。仅当您使用特定功能时，我们才会根据“最小必要”原则，为实现向您提供服务的目的，处理您的相关个人信息。
    </div>
    <div>
      除本政策外，在特定场景下，我们将以及时告知的方式（包括但不限于更新本政策、重新签署文件、页面提示、弹窗、站内信、邮件、网站公告或其他便于您获知的方式）另行向您详细说明对应信息的处理目的、方式、范围等规则，并在征得您的授权同意后处理。
    </div>
    <br />
    <div class="bold">本《青点互动隐私政策》将帮助您了解以下内容</div>
    <div><span class="dian">•</span>一、本政策适用范围、相关词语涵义</div>
    <div><span class="dian">•</span>二、我们如何收集和使用您的个人信息</div>
    <div><span class="dian">•</span>三、对 Cookie 和同类技术的使用</div>
    <div>
      <span class="dian">•</span>四、我们如何共享、转让、公开披露您的个人信息
    </div>
    <div><span class="dian">•</span>五、我们如何存储和保护您的个人信息</div>
    <div><span class="dian">•</span>六、您的权利</div>
    <div><span class="dian">•</span>七、未成年人保护</div>
    <div><span class="dian">•</span>八、隐私政策的修订和通知</div>
    <div><span class="dian">•</span>九、适用范围</div>
    <div><span class="dian">•</span>十、联系我们</div>
    <div><span class="dian">•</span>十一、其他</div>
    <br />
    <br />
    <div class="bold">一、本政策适用范围、相关词语涵义</div>
    <div class="bold">(一) 适用范围</div>
    <div>
      本政策适用于青点互动提供的产品与/或服务，但如某些产品与/或服务单独设置了隐私条款/隐私协议的，单独的隐私条款/隐私协议优先适用，单独的隐私条款/隐私协议未提及的内容，适用本政策。
    </div>
    <div>请您注意，本政策不适用于以下情况：</div>
    <div>
      <span class="dian">•</span
      >我们的产品与/或服务中包含的或链接至第三方提供的信息与/或第三方服务（包括任何第三方应用、网站、产品、服务等），这些信息与/或服务由第三方负责运营，您使用该信息与/或服务适用第三方另行向您说明的个人信息处理规则。
    </div>
    <div>
      <span class="dian">•</span>其他非青点互动集团向您提供的产品与/或服务内容。
    </div>
    <div class="bold">(二) 相关词语涵义</div>
    <div>
      <span class="dian">•</span
      >我们的产品与/或服务：包括但不限于我们提供的软件、网站、服务（含站外服务，例如：我们的广告服务和“通过我们的服务申请或分享”的插件等）。
    </div>
    <div>
      <span class="dian">•</span
      >设备信息：通常包括设备名称、设备型号、硬件序列号、MAC地址、设备标识信息（IMEI/MEID/AndroidID/OpenUDID/IMSI/IDFA/IDFV/OAID/ICCID及其他综合设备参数及系统信息形成的设备标识符）、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）、IP地址、浏览器类型。
    </div>
    <div>
      <span class="dian">•</span
      >服务日志信息：包括但不限于正在安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源等服务日志。
    </div>
    <br />
    <div class="bold">二、我们如何收集和使用您的个人信息</div>
    <div>
      通讯录、位置、相机、相册（存储）、麦克风、日历等系统权限，均不会默认开启，只有经过您单独同意后，才会在为实现特定服务/功能时访问。<span
        class="bold"
        >即使经过您的授权，可以访问您的相应权限，我们仅会在您的授权范围内进行访问,并获取相应信息（如涉及）；且您可随时撤回授权，在您撤回授权后，我们将停止访问相应权限，也将无法再为您提供与之对应的某些服务，但不影响您享受我们提供的其他服务。</span
      >
    </div>
    <div class="bold">
      我们主要通过以下途径收集您在使用我们产品与/或服务时涉及的个人信息：
    </div>
    <div>
      (一) 根据业务需要您主动提供的信息，例如：注册账号时提供的手机号码。
    </div>
    <div>
      (二) 我们收集、记录、汇总的信息，例如：您的设备信息、个人上网记录。
    </div>
    <div>
      (三)
      我们从第三方间接获取的您的信息，例如：联名会员购买时合作商提供的手机号码。
    </div>
    <div class="bold">
      通常情况下，我们会出于以下功能/场景的实现，收集和使用您的个人信息或申请相关权限：
    </div>
    <div class="bold">(一) 账号注册与登录</div>
    <div>
      注册/登录：当您注册、登录我们的产品与/或服务时，为了帮您顺利完成注册/登录流程，您需要向我们提供您的<span
        class="bold"
        >手机号码。收集前述信息是为了满足相关法律法规的网络实名制要求，如您拒绝提供手机号码进行核验，将导致注册/登录失败，但不影响您使用视频的浏览、搜索、播放功能。</span
      >
    </div>
    <div>
      一键登录：基于我们与通信运营商的合作，当您使用
      “一键登录”功能时，经过您的同意，运营商会将您的<span class="bold"
        >手机号码</span
      >发送给我们，便于我们为您提供快捷的登录服务。
    </div>
    <div>
      授权登录：您使用第三方平台的账号授权登录时，您授权我们获取该第三方账号下的相关信息<span
        class="bold"
        >（包括昵称、头像，具体以您的授权范围为准）</span
      >，用于与青点互动账号绑定、您网络资料信息的完善。
    </div>
    <div class="bold">(三) 服务内容搜索/播放</div>
    <div>
      当您使用搜索功能时，我们会自动收集您的一些信息，包括如下个人信息：<span
        class="bold"
        >搜索的内容、浏览记录和时间、搜索的时间以及与它们互动的次数。</span
      >为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地设备端，并向您展示搜索历史记录。
    </div>
    <div>
      为方便您更快捷地进行搜索并避免手动操作粘贴，我们为您提供了复制信息展示的便捷功能。该功能开启后，您使用搜索功能时，在搜索页面上将展示您剪贴板上最近一次的复制信息，以为您快速提供您所需要的内容。<span
        class="bold"
        >请您放心，我们仅会对您的剪贴板最近一次复制的信息进行展示，不会收集您剪贴板上的内容也不会将前述信息上传至我们的服务器。</span
      >只有当您在搜索框中确认填入前述信息并点击搜索后，为了实现搜索功能我们会收集该信息。您可以在相关设置页面开启/关闭该展示功能。
    </div>
    <div class="bold">(四) 信息制作、发布</div>
    <div>
      当您使用图片、发布、帖子、评论等功能时，您可能会主动提供相关图文/音视频内容信息。我们会收集您的上述信息，并展示您的<span
        class="bold"
        >昵称、头像、发布的内容。您发布的信息中可能包含他人的个人信息，请您务必取得他人的合法授权，避免非法泄露他人的个人信息</span
      >。就前述部分信息的制作、发布功能，在您使用时，我们会请求您授权相机、相册、麦克风权限。
    </div>
    <div class="bold">(五) 交流互动</div>
    <div>
      当您进行交流互动时，我们会收集您的<span class="bold"
        >互动记录(包括评论/分享/关注等互动记录)、互动内容（包括评论、弹幕等交流互动信息）。</span
      >
    </div>
    <div class="bold">(六) 运营及产品安全需要</div>
    <div>
      我们需要收集您的一些信息来保障您使用我们的产品与/或服务时的账号与系统安全，防止恶意程序及反作弊、反欺诈，并协助提升我们的产品与/服务的安全性和可靠性，以防产生任何危害用户、青点互动集团、社会的行为，包括：<span
        class="bold"
        >设备信息、个人上网记录、服务日志信息、交易信息、账号及实名认证相关信息、地理位置信息（包括通过IP、WLAN等网络位置信息获取的大致地理位置信息及通过GPS信息、WLAN接入点(如
        BSSID、SSID)、蓝牙和基站等传感器信息获取的精确地理位置信息）及依法取得您授权后自我们关联公司、合作伙伴处收集的信息。</span
      >
    </div>
    <div>
      我们会根据上述信息来综合判断您的账号及交易风险、进行身份验证、检测及防范安全事件、诈骗监测、存档和备份用途，并依法采取必要的记录、审计、分析、处置措施。
    </div>
    <div>
      上述信息在去标识化或匿名化处理后，可能用于内部研究、统计分析和预测，改善我们平台及服务的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练）。
    </div>
    <div class="bold">(七) 客服及其他用户响应功能</div>
    <div>
      当您使用我们的客户服务或其他用户响应功能时，为了您的账号与系统安全，我们可能需要您先行提供<span
        class="bold"
        >账号信息</span
      >，并与您之前的个人信息相匹配以验证您的用户身份。同时，我们可能还会需要收集<span
        class="bold"
        >您的如下个人信息：联系方式（您与我们联系时使用的电话号码/电子邮箱或您向我们主动提供的其他联系方式）、您与我们的沟通信息（包括文字/图片/音视频/通话记录形式）、与您需求相关联的其他必要信息</span
      >。我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供可能导致您无法使用我们的客服等用户响应功能。
    </div>
    <div class="bold">(八) 其他功能</div>
    <div>
      如果某一需要收集您的个人信息的功能或产品/服务未能在本政策中予以说明的，或者我们超出了与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，我们会再次向您告知，并根据法律法规的相关要求征得您的同意。
    </div>
    <div>例外情形</div>
    <div>
      我们在以下情况下处理（包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等）您的个人信息无需您的授权同意：
    </div>
    <div>
      <span class="dian">•</span>为订立、履行个人作为一方当事人的合同所必需；
    </div>
    <div>
      <span class="dian">•</span
      >为履行法定职责或者法定义务所必需（包括与（可能的）诉讼、仲裁、执行等法律程序直接相关的；或根据法律法规的要求、行政机关或公检法等有权机关的要求的）；
    </div>
    <div>
      <span class="dian">•</span
      >为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    </div>
    <div>
      <span class="dian">•</span
      >为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
    </div>
    <div>
      <span class="dian">•</span
      >依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
    </div>
    <div><span class="dian">•</span>法律、行政法规规定的其他情形。</div>
    <br />
    <div class="bold">三、对 Cookie 和同类技术的使用</div>
    <div>
      Cookie
      和同类技术是互联网中的通用常用技术。当您使用本应用软件及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个
      Cookie 或匿名标识符记录您的登录状态，方便您快捷登录。
    </div>
    <div>
      我们承诺，我们不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。
    </div>
    <br />
    <div class="bold">四、我们如何共享、转让、公开披露您的个人信息</div>
    <div class="bold">(一) 共享</div>
    <div>
      尊重用户个人隐私是我们的一项基本原则。除以下情形外，我们不会与任何公司、组织和个人分享你的个人信息：
    </div>
    <div>
      1.
      在获取明确同意的情况下共享：获得你的明确同意后，我们会与其他方共享你的个人信息。
    </div>
    <div>2. 我们可能会按照司法机关或行政机关的要求，对外共享你的个人信息。</div>
    <div>
      3.
      与我们的关联公司共享：你的个人信息可能会与的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求你的授权同意。
    </div>
    <div>
      4.
      与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享你的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享你的个人信息，并且只会共享提供服务所必要的个人信息，例如在你上网购买我们的产品时必须与物流公司共享你的信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
    </div>
    <div>
      对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    </div>
    <div class="bold">(二) 转让</div>
    <div>
      我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </div>
    <div>
      1.
      在获取明确同意的情况下转让：获得你的明确同意后，我们会向其他方转让你的个人信息。
    </div>
    <div>
      2.
      在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有你个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向你征求授权同意。<span
        class="agreement"
        @click="listingHerf()"
        >第三方信息共享清单</span
      >
    </div>
    <div class="bold">(三) 公开披露</div>
    <div>我们仅会在以下情况下，公开披露你的个人信息：</div>
    <div>1. 获得你明确同意后。</div>
    <div>
      2.
      基于法律的披露：在法律、法律程序、诉讼或政府主管部分强制性要求的情况下，我们可能会公开披露你的个人信息。们在遵守“合法正当、最小必要、目的明确原则”的前提下共享您的个人信息。
    </div>
    <br />
    <div class="bold">五、我们如何存储和保护您的个人信息</div>
    <div class="bold">(一) 个人信息的存储</div>
    <div>
      1. 存储地点：<span class="bold"
        >我们在中华人民共和国境内运营中收集和产生的个人信息存储于中华人民共和国境内。</span
      >仅在以下情形，且在满足法律法规及监管部门的相关规定后，我们可能会向境外主体提供您的个人信息，并将严格要求接收方按照相关法律法规要求及本政策来处理您的个人信息：（1）法律法规有明确规定；（2）另行获得您单独同意；（3）您使用的产品与/或服务涉及跨境，您主动向境外提供个人信息。
    </div>
    <div>
      2.
      存储期限：我们在为提供我们的产品与/或服务之目的所必需且最短的期间内保留您的个人信息，例如：当您使用我们的注册/登录功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续为您保留您的手机号码，以向您正常提供该功能、保障您的账号和系统安全。
    </div>
    <div>
      在您主动删除个人信息、注销账号或超出必要的存储期限后，我们会对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。
    </div>
    <div>
      我们判断个人信息的必要存储期限主要依据以下标准：（1）维护相应交易及业务记录，以应对您可能的查询或投诉；（2）保证我们为您提供服务的安全和质量；（3）您是否同意更长的留存期间。
    </div>
    <div class="bold">(二) 个人信息的保护措施</div>
    <div>
      我们一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术及组织和管理等保护措施以最大程度降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。包括：
    </div>
    <div>
      (1)
      采用多种安全技术措施对数据的收集、传输、存储、处理、销毁、审计等环节进行管控。
    </div>
    <div>
      (2)
      建立个人信息安全保护组织，落实个人信息安全规范处理、信息安全宣贯与培训等措施。
    </div>
    <div>
      (3)
      构建并完善包括安全体系认证、业务安全能力认证及网络安全等级保护认证的个人信息安全管理体系。
    </div>
    <div>
      (4)
      建立安全应急响应体系、安全事件应急处理机制，及时、高效完成安全事件处理。
    </div>
    <div>
      请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码、定期修改密码、不将自己的账号密码及相关个人信息披露给他人等方式，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄露，请您立即根据本隐私政策文末中提供的联系方式联络我们，以便我们采取相应措施来保护您的信息安全。
    </div>
    <br />
    <div class="bold">六、您的权利</div>
    <div>
      我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、
      注销账号、投诉举报以及设置隐私功能等权利，以使您有能力保障您的隐私和安全。
    </div>
    <div class="bold">（一）访问、删除、更正您的个人信息</div>
    <div>
      在您使用本应用服务期间，我们可能会视应用具体情况为您提供相应的操作设置，以便您可以访问、删除、更正您的相关个人信息，您可参考相应应用产品的具体指引进行操作。
    </div>
    <div>
      特别提示您注意，出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法自主修改注册时提交的某些初始注册信息。如您确有需要修改该类注册信息，请根据本隐私政策载明的联系方式联系我们。
    </div>
    <div class="bold">（二）改变您授权同意范围或撤销授权</div>
    <div>
      您可以在设备本身操作系统中关闭相关敏感权限改变同意范围或撤回您的授权。
    </div>
    <div>
      请您理解，特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。
    </div>
    <div class="bold">（三）注销您的账号</div>
    <div>注销路径：我的-设置中心-账号安全-账号注销</div>
    <div>
      如果您不再希望使用我们的服务，您可以通过向我们发送邮件的方式申请注销账号，三个工作日内审核后即可注销成功，当您注销账号后，我们将立即删除有关您的相关信息，但法律法规另有规定的除外。在您注销账号前，我们将验证您的个人身份、安全状态、设备信息等。请您知悉并理解，注销账号的行为是不可逆的行为，当您注销账号后，我们将删除有关您的相关信息，但法律法规另有规定的除外。
    </div>
    <div class="bold">（四）访问隐私政策</div>
    <div>
      您可以在注册登录页面，查看本隐私政策的全部内容或在应用中查看本隐私政策全部内容。
    </div>
    <div class="bold">（五）停止运营向您告知</div>
    <div>
      如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
    </div>
    <br />
    <div class="bold">七、未成年人保护</div>
    <div>
      青点互动集团一直非常注重对未成年人的保护，致力于践行我们的企业社会责任。
    </div>
    <div>
      如您为未成年人，应首先取得其监护人的同意（包括本政策），在监护人同意后和指导下进行使用、提交个人信息。
    </div>
    <div>
      如果您是14周岁以下儿童的，在您使用我们的服务前，请您通知您的监护人阅读我们的《青点互动儿童个人信息保护规则》，并请您在监护人的授权同意后，在监护人的指导下使用我们的产品与/或服务。
    </div>
    <div>
      对于经监护人同意使用我们的产品与/或服务的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下收集、使用、共享、转让或公开披露。
    </div>
    <br />
    <div class="bold">八、隐私政策的修订和通知</div>
    <div>
      1.
      为给您提供更好的服务，本应用软件及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。
    </div>
    <div>
      2.
      本隐私政策更新后，我们会在本应用发出更新版本，并在更新后的条款生效前通过应用内公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。
    </div>
    <br />
    <div class="bold">九、适用范围</div>
    <div>
      本隐私政策适用于由我们提供的本应用及相关服务，不适用于有单独的隐私政策且未纳入本隐私政策的第三方产品或服务。
    </div>
    <div>本隐私权政策不适用于：</div>
    <div>
      1. 其他第三方产品或服务，包括在本应用及相关服务中链接到的其他产品或网站；
    </div>
    <div>2. 为本应用及相关服务进行广告宣传的其他第三方。</div>
    <div>
      您使用这些第三方服务（包括您向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请您仔细阅读第三方的条款。请您妥善保护自己的个人信息，仅在必要的情况下向第三方提供。
    </div>
    <div>
      请您了解，本隐私政策中所述的本应用及相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的本应用及相关服务为准。
    </div>
    <br />
    <div class="bold">十、联系我们</div>
    <div>
      如果您对个人信息保护问题及本隐私政策内容有投诉、建议、意见、疑问，您可以通过以下联系方式和我们反馈
    </div>
    <div>公司主体：海南乐游科技有限公司</div>
    <div>电子邮件：leyou@Boomgames.top</div>
    <br />
    <div class="bold">十一、其他</div>
    <div class="bold">
      (一)
      本政策的解释及争议解决均应适用中华人民共和国大陆地区法律。如就本政策的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。
    </div>
    <div>
      (二)
      本政策的标题仅为方便及阅读而设，不影响本协议任何条款的含义或解释。集团提供的产品与/或服务，但如某些产品与/或服务单独设置了隐私条款/隐私协议的，单独的隐私条款/隐私协议优先适用，单独的隐私条款/隐私协议未提及的内容，适用本政策。
    </div>
    <div>请您注意，本政策不适用于以下情况：</div>
    <div>
      <span class="dian">•</span
      >我们的产品与/或服务中包含的或链接至第三方提供的信息与/或第三方服务（包括任何第三方应用、网站、产品、服务等），这些信息与/或服务由第三方负责运营，您使用该信息与/或服务适用第三方另行向您说明的个人信息处理规则。
    </div>
    <div>
      <span class="dian">•</span>其他非青点互动集团向您提供的产品与/或服务内容。
    </div>
    <div class="bold">(二) 相关词语涵义</div>
    <div>
      <span class="dian">•</span
      >我们的产品与/或服务：包括但不限于我们提供的软件、网站、服务（含站外服务，例如：我们的广告服务和“通过我们的服务申请或分享”的插件等）。
    </div>
    <div>
      <span class="dian">•</span
      >设备信息：通常包括设备名称、设备型号、硬件序列号、MAC地址、设备标识信息（IMEI/MEID/AndroidID/OpenUDID/IMSI/IDFA/IDFV/OAID/ICCID及其他综合设备参数及系统信息形成的设备标识符）、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）、IP地址、浏览器类型。
    </div>
    <div>
      <span class="dian">•</span
      >服务日志信息：包括但不限于正在安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源等服务日志。
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy',
  data () {
    return {}
  },
  mounted () {},
  methods: {
    agreement () {
      let routerData = this.$router.resolve({
        path: '/children'
      })
      window.open(routerData.href, '_blank')
    },
    listingHerf () {
      let routerData = this.$router.resolve({
        path: '/listing'
      })
      window.open(routerData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.privacy {
  word-break: break-all;
  word-wrap: break-word;
  padding-bottom: 20px;
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .dian {
    display: inline-block;
    color: royalblue;
    margin-right: 10px;
  }
  .agreement {
    color: royalblue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
